const initialState = {
  fingerprint: [],
  loading: true,
};

const fingerprintReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getFingerprint":
      return {
        ...state,
        fingerprintData: action.payload,
        loading: false,
      };

    case "createFingerprint":
      return {
        ...state,
        fingerprintData: action.payload,
        loading: false,
      };

    case "updateFingerprint":
      return {
        ...state,
        fingerprintData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default fingerprintReducer;
