import "../styles/globals.scss";
import "antd/dist/reset.css";
import "react-quill/dist/quill.snow.css";
// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import "swiper/css/navigation";

import Error from "next/error";
import nextCookie from "next-cookies";
import { appWithTranslation } from "next-i18next";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { Api } from "../networks/Api";
import axios from "axios";

const App = ({ Component, pageProps, categoryMenu, hasSale, hasLive }) => {
  if (pageProps?.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Prompt",
          },
        }}
      >
        {/* เอาอันนี้ออกแล้วหน้าเว็บจะไม่ขึ้น progress bar */}
        {/* <NextNProgress /> */}
        <Component
          {...pageProps}
          categoryMenu={categoryMenu}
          hasSale={hasSale}
          hasLive={hasLive}
        />
      </ConfigProvider>
    </Provider>
  );
};

App.getInitialProps = async ({ Component, ctx }) => {
  const c = nextCookie(ctx);
  let pageProps = {};
  let categoryMenu, hasSale, hasLive;

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  try {
    const url = `${Api.getGroupCategory}`;
    const response = await axios.get(url, Api.Header());
    if (response?.status == 200) {
      const cate = response?.data?.data;
      categoryMenu = cate;
    }

    const url_has_sale = `${Api.checkHasSaleProduct}`;
    const response_has_sale = await axios.get(url_has_sale, Api.Header());
    if (response_has_sale?.status == 200) {
      hasSale = response_has_sale?.data?.has_sale_product;
    }

    const url_has_live = `${Api.checkHasLiveProduct}`;
    const response_has_live = await axios.get(url_has_live, Api.Header());
    if (response_has_live?.status == 200) {
      hasLive = response_has_live?.data?.has_sale_product;
    }
  } catch (e) {
    console.log("fetchData >> getGroupCategory error :", e);
  }

  if (c.token == "undefined" || c.token == null) {
    return { pageProps, categoryMenu, hasSale, hasLive };
  } else {
    return {
      ...pageProps,
      ...{ query: ctx.query },
      categoryMenu,
      hasSale,
      hasLive,
    };
  }
};

export default appWithTranslation(App);
