import { combineReducers } from "redux";

// Import all reducers
import footballersReducer from "./footballersReducers";
import cartReducer from "./cartReducers";
import currencyAllReducer from "./currencyAllReducers";
import currencyReducer from "./currencyReducers";
import fingerprintReducer from "./fingerprintReducers";

const reducers = combineReducers({
  footballers: footballersReducer,
  cart: cartReducer,
  currencyAll: currencyAllReducer,
  currency: currencyReducer,
  fingerprint: fingerprintReducer,
});

export default reducers;
