const initialState = {
  currencyData: "thb",
  loading: true,
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "getCurrency":
      return {
        ...state,
        currencyData: action.payload,
        loading: false,
      };

    case "createCurrency":
      return {
        ...state,
        currencyData: action.payload,
        loading: false,
      };

    case "updateCurrency":
      return {
        ...state,
        currencyData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default currencyReducer;
