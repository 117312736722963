const initialState = {
  cart: [],
  loading: true,
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getCart':
      return {
        ...state,
        cartData: action.payload,
        loading: false,
      }

    case 'createCart':
      return {
        ...state,
        cartData: action.payload,
        loading: false,
      }

    case 'updateCart':
      return {
        ...state,
        cartData: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default cartReducer
